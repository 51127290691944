import React, { useState, useEffect } from 'react'

import image_mycawan_staff from "../../images/image_mycawan_staff.png"
import mycawan_staff_logo from "../../images/mycawan_staff_logo.png"
import playstore_link_logo from "../../images/playstore_link.png"
import appstore_link_logo from "../../images/appstore_link.png"
import '../../styles/global.css'

export default function MycawanStaff() {
    const [matches, setMatches] = useState(true);

    useEffect(() => {
        setMatches(window.matchMedia("(min-width: 768px)").matches)
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    const playStoreLink = "https://play.google.com/store/apps/details?id=com.mycawan.mycawan_staff";
    const appStoreLink = "https://apps.apple.com/fr/app/netresto-mobile-app/id6462230560";

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div style={{
            display: "flex", background: "#CADCF6",
            flexDirection: matches ? "row" : "column",
        }}>
            <div style={{
                width: "50vw",
                display: "flex",
                flexDirection: "column",
                padding: "0 0 0 10vw",
            }}>
                <div style={{
                    padding: "8vh 20vw 8vh 0"
                }}>

                    <img style={{
                        // padding: "0vh 20vw 0vh 0"
                    }} src={mycawan_staff_logo} />
                </div>
                <p style={{ fontSize: "4vh", margin: 0, lineHeight: "1.2em", fontFamily: "Source Serif Pro semi-bold" }}>Finis le papier,</p>
                <p style={{ fontSize: "4vh", margin: 0, lineHeight: "1.2em", fontFamily: "Source Serif Pro semi-bold" }}>les emails et demandes</p>
                <p style={{ fontSize: "4vh", margin: 0, lineHeight: "1.2em", fontFamily: "Source Serif Pro semi-bold" }}>informelles.</p>
                <p style={{ fontSize: "4vh", margin: 0, lineHeight: "1.2em", fontFamily: "Source Serif Pro semi-bold" }}>Place à mycawan staff!</p>
                <p style={{ margin: "4vh 0 1vh 0", fontSize: "1.2em" }}>1. Téléchargez l'application sur mobile.</p>
                <div style={{ display: "flex", }}>
                    <div>
                        <img
                            style={{
                                height: "6vh",
                                padding: "0 1vw 2vh 0",
                            }}
                            src={playstore_link_logo}
                            onClick={() => openInNewTab(playStoreLink)}
                        />
                    </div>
                    <div>
                        <img
                            style={{
                                height: "6vh",
                                padding: "0 1vw 2vh 0",
                            }}
                            src={appstore_link_logo}
                            onClick={() => openInNewTab(appStoreLink)}
                        />
                    </div>
                    <div></div>
                </div>
                <p style={{ margin: "3vh 0 0 0", fontSize: "1.2em", fontFamily: "Lexand Deca regular" }}>2. Appuyez sur le lien de création de compte</p>
                <p style={{ margin: 0, fontSize: "1.2em", fontFamily: "Lexand Deca regular" }}>sur l'email d'invitation depuis votre appareil mobile.</p>

            </div>
            <div style={{ width: "50vw" }}>
                <img
                    style={{ height: "100vh" }}
                    src={image_mycawan_staff}
                />
            </div>

        </div>
        // <LayoutStaff>
        //     {/* <div style={{ textAlign: "center", margin: "2em 0" }}>
        //         <IconButton onClick={() => openInNewTab(playStoreLink)}>
        //             <FaAndroid style={{ verticalAlign: "middle", color: googleColor, fontSize: "2em" }} />
        //         </IconButton>

        //         <a href={playStoreLink} target="_blank">

        //             <Typography align="center" variant="string" component="div">
        //                 <>
        //                     mycawan Staff sur Android !!
        //                 </>
        //             </Typography>
        //         </a>
        //     </div>
        //     <div style={{ textAlign: "center", margin: "2em 0" }}>
        //         <IconButton onClick={() => openInNewTab(appStoreLink)}>
        //             <FaApple style={{ verticalAlign: "middle", color: appleColor, fontSize: "2em" }} />
        //         </IconButton>

        //         <a href={appStoreLink} target="_blank">

        //             <Typography align="center" variant="string" component="div">
        //                 <>
        //                     mycawan Staff sur iOS !!
        //                 </>
        //             </Typography>
        //         </a>
        //     </div> */}
        //     <div style={{display: "flex",}}>
        //         <div>
        //             <p>text</p>
        //         </div>
        //         <div>
        //             <img 
        //                 style={{height: '100vh'}}
        //                 src={image_mycawan_staff}
        //             />
        //         </div>

        //     </div>
        // </LayoutStaff >
    )

}
